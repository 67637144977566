/* eslint-disable no-unused-vars */
import React from "react";
import { useState } from "react";
import { Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TextDecrypt } from "../content/TextDecrypt";

import './Works.css';

// Import ../../assets/recentprojects/
import FoxFinance from '../../assets/recentprojects/foxfi.png';
import TrenFinance from '../../assets/recentprojects/tren-finance.jpg';
import RuneLaunchpad from '../../assets/recentprojects/runes-launchpad.png';
import Bitmap from '../../assets/recentprojects/bitmap.png';
import Dice from '../../assets/recentprojects/Dice.png';
import Dusty from '../../assets/recentprojects/dusty.png';
import Tac from '../../assets/recentprojects/TAC.png';

const useStyles = makeStyles((theme) => ({
  main: {
    maxWidth: '100vw',
    marginTop: '3em',
    marginBottom: "auto",
  },
}));

export const Works = () => {
  const classes = useStyles();
  const [projects, setProjects] = useState([
    {
      id: 1,
      title: 'Fox Finance',
      description: `NFT marketplace on Ethereum. Clients can buy, sell nfts, playing game, staking so on.
        My role was solidity smart contract developer.
        Tech stack - Solidity, Web3, Vue.js, Node.js, MongoDB`,
      alter: 'LoFo Project',
      image: `${FoxFinance}`,
    },
    // {
    //   id: 2,
    //   title: 'Ordinal LaunchPad',
    //   description: `Ordinal inscription service.
    //     Client can inscribe their ordinals on this site.
    //     Tech stack - Bitcoin ordinal, inscription, typescript, node.js`,
    //   alter: 'Ordinal LaunchPad',
    //   image: `${Bitmap}`,
    // },
    {
      id: 2,
      title: 'Tren Finance',
      description: `This is forking project for MakerDao system. 
        It's implemented by Stablecoin with collateral assets. 
        My role was Web3 and Frontend developer.
        Tech stack: Solidity, Web3, React.js, Node.js, MongoDB`,
      alter: 'NFT Marketplace',
      image: `${TrenFinance}`,
    },
    // {
    //   id: 3,
    //   title: 'Rune LacuchPad',
    //   description: `Rune eching platform.
    //     Client get runes from this platform.
    //     Programing language - Typescript`,
    //   alter: 'Bitcoin Rune',
    //   image: `${RuneLaunchpad}`,
    // },
    {
      id: 3,
      title: 'Tac NFT Staking',
      description: `A website for NFT staking.
      NFT staking and earning,
      Tech stack - Solidity, Node.js, React.js.
      My role was Smart contract and backend developer.`,
      alter: 'NFT staking ',
      image: `${Tac}`,
    },
    {
      id: 4,
      title: 'Dice',
      description: `Dice gambling project with Anchor on Solana. 
        Clients can play and earn(P2E) there. 
        Developed it from scratch.
        Tech stack - Rust, Anchor, Next.js`,
      alter: 'P2E game',
      image: `${Dice}`,
    },
    {
      id: 5,
      title: 'Dusty Vaults',
      description: `Safe, boring NFT vault. Clients can pay to store their NFT’s for the next year.
        Built from scratch. Used React.js, Web3`,
      alter: 'P2E game',
      image: `${Dusty}`,
    },
  ]);

  return (
    <section id="works">
      <Container component="main" className={classes.main} maxWidth="md">
        {projects.map((project) => (
          <div className="project" key={project.id}>
            <div className="__img_wrapper">
              <img src={project.image} alt={project.alter} />
            </div>
            <div className="__content_wrapper">
              <h3 className="title">
                <TextDecrypt text={project.id + '. ' + project.title} />
              </h3>
              <p className="description">
                {project.description}
              </p>
            </div>
          </div>
        ))}
      </Container>
    </section>
  );
};
